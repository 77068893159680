<!--
 * @Description: 
 * @Author: 张宏军
 * @Date: 2024-10-29 21:59:48
 * @LastEditTime: 2024-10-29 22:50:29
 * @LastEditors: 张宏军
-->

<template>
  <div id="app">
    <!-- <img src="./assets/logo.png">
    <div>
      <p>
        If Element is successfully added to this project, you'll see an
        <code v-text="'<el-button>'"></code>
        below
      </p>
      <el-button>el-button</el-button>
    </div>
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view />
   
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'app',
  components: {
    // HelloWorld
  }
}
</script>

<style>
#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
.Liability{
    font-size: 12px;
    line-height: 15px;
    background-color: #fff;
  }
.Liability .LiabilityC{
  padding: 20px 20px 5px 20px;
  max-width: 1440px;
  margin:0 auto;
}
</style>
