import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/css/reset.css'
import './assets/css/font.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import Vconsole from 'vconsole';
// let vConsole = new Vconsole();
// export default vConsole

// createApp(App).mount('#app').use(ElementPlus)
// const routerPush = router.prototype.push
// router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(error=> error)
// }

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


import env from './util/env'
if (env.getEnv() === 'app') {
  window.env = 'app'
  if (env.isPad()) {
    window.env = 'pad'
  }
} else if (env.getEnv() === 'wxApp') {
  window.env = 'wxApp'
  if (env.isPad()) {
    window.env = 'pad'
  }
} else if (env.getEnv() === 'aliApp') {
  window.env = 'aliApp'
  if (env.isPad()) {
    window.env = 'pad'
  }
} else if (env.getEnv() === 'mobile') {
  window.env = 'mobile'
  if (env.isPad()) {
    window.env = 'pad'
  }
} else if (env.getEnv() === 'pc') {
  window.env = 'pc'
}
