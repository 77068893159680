export default {
  getEnv() {
    var ua = navigator.userAgent

    if (window.flutter_inappwebview) {
      return 'app'
    }

    // var isApp = /(?::estore-app)/.test(ua)
    // // alert("111111estore-app:::"+isApp)
    // if (isApp) { // 原生APP
    //   return 'app'
    // }

    // alert('wxApp')
    // return 'wxApp'

    var ualower = ua.toLowerCase()
    if (/micromessenger/.test(ualower)) {
      // alert(/micromessenger/.test(ualower) + '123')
      if (/miniprogram/i.test(ua)) { // 微信小程序
        // alert(window.__wxjs_environment + 'miniprogram')
        return 'wxApp'
      }
    }

    if (/AliApp/i.test(ua) && /miniprogram/i.test(ua)) {
      return 'aliApp' // 支付宝小程序
    }

    if (/samsungmembers/.test(ualower) || ualower.indexOf('samsungmembers') !== -1) {
      return 'mobile'
    }

    var isFireFox = /(?:Firefox)/.test(ua)
    var isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
    var isWindowsPhone = /(?:Windows Phone)/.test(ua)

    var isPhone = /(?:iPhone)/.test(ua) && !isTablet
    var isAndroid = /(?:Android)/.test(ua)
    var isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone

    var isMobile = isPhone || isAndroid || isSymbian
    if (isMobile) { // 手机
      return 'mobile'
    }

    // PC和平板
    return 'pc'
  },
  isPad() {
    // alert('document.body.clientWidth ' + document.body.clientWidth)
    if (this.getEnv() === 'app' || this.getEnv() === 'wxApp' || this.getEnv() === 'mobile' || this.getEnv() === 'aliApp') {
      if (document.body.clientWidth > 925) {
        return true
      } else {
        return false
      }
    }
  }
}
