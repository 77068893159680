/*
 * @Description: 
 * @Author: 张宏军
 * @Date: 2024-10-29 21:59:49
 * @LastEditTime: 2024-11-03 12:26:33
 * @LastEditors: 张宏军
 */

import {createRouter} from "vue-router"
import {createWebHashHistory,createWebHistory} from "vue-router"
 
// const helloWorld = () => import("../components/HelloWorld")
// const index = () => import("../page/index")

const routes = [
    {
      path: "/map",
      name: "map",
      component: () => import("../page/index")
    },
    {
      path: "/",
      name: "home",
      component: () => import("../page/home")
    },
  ]
 const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_HISTORY_URL),
    // mode: 'history',
    // base:'/wecom/',
    routes
  })
// const router =  new VueRouter({
//   mode: 'hash', // history
//   routes:[
//     {
//         //主页
//         path:"/",
//         component:home
//     },
//   ]
// })	//返回实例


export default router